import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import heic2any from "heic2any";
import {
  Col,
  Row,
  Card,
  Form,
  Button,
  Container,
} from "@themesberg/react-bootstrap";
import {
  AddUpdateUser,
  UploadImages,
  getuser,
} from "../Commanservice/commanservice.dev";
import { ToastContainer, toast } from "react-toastify";
import config from "../config/axios/config.json";
const Mode = config.Application.mode;
const APIURL = config["CommonConstants"].web[Mode];
const fileTypes = ["JPG", "PNG", "GIF"];
export const GeneralInfoForm = () => {
  const [birthday, setBirthday] = useState("");

  return (
    <Container className="px-0">
      <h2 className="mb-4">General Information</h2>
      <Row>
        <Col xs={12} className="p-3">
          <Card>
            <Card.Body>
              <article>
                <p className="fs-5 fw-light"></p>
                <p>
                  <b>Industrial plantations</b> are actively managed for the
                  commercial production of forest products. Industrial
                  plantations are usually large-scale. Individual blocks are
                  usually even-aged and often consist of just one or two
                  species. These species can be exotic or indigenous. The plants
                  used for the plantation are often genetically altered for
                  desired traits such as growth and resistance to pests and
                  diseases in general and specific traits, for example in the
                  case of timber species, volumic wood production and stem
                  straightness.
                </p>
                <ul className="docs-list">
                  <li>
                    Cleaner air across the country{" "}
                    <Card.Link
                      href="https://reactjs.org/"
                      target="_blank"
                    ></Card.Link>
                  </li>
                  <li>
                    Cleaner water and natural filtration
                    <Card.Link
                      href="https://react-bootstrap.github.io/"
                      target="_blank"
                    ></Card.Link>
                  </li>
                  <li>
                    Carbon sequestration{" "}
                    <Card.Link
                      href="https://getbootstrap.com/"
                      target="_blank"
                    ></Card.Link>
                  </li>
                  <li>
                    Controlled temperatures
                    <Card.Link
                      href="https://sass-lang.com/"
                      target="_blank"
                    ></Card.Link>
                  </li>
                </ul>

                <h5 id="getting-support"></h5>
                {/* <p>We offer 6 months of support by default for each purchased template. Please <Card.Link href="https://themesberg.com/contact" target="_blank">contact us</Card.Link> and we&rsquo;ll get back to you in no time!</p> */}

                <h5 id="community"></h5>
                {/* <ul className="docs-list">
                <li>Follow <Card.Link href="https://twitter.com/themesberg" target="_blank">@themesberg on Twitter</Card.Link>.</li>
                <li>Follow <Card.Link href="https://facebook.com/themesberg" target="_blank">Themesberg on Facebook</Card.Link>.</li>
                <li>Read and subscribe to <Card.Link href="https://themesberg.com/blog" target="_blank">The Official Themesberg Blog</Card.Link>.</li>
                <li>Follow latest open source projects on our <Card.Link href="https://github.com/themesberg" target="_blank">Github Page</Card.Link></li>
              </ul> */}
              </article>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export const NewUserForm = () => {
  const [certNo, setcertNo] = useState("");
  const [IntegraPoCUpdated, setIntegraPoCUpdated] = useState("");
  const [KAMHead, setKAMHead] = useState("");
  const [CustomerName, setCustomerName] = useState("");
  const [EMail, setEMail] = useState("");
  const [OriginationGroupName, setOriginationGroupName] = useState("");
  const [OriginalOrganizationName, setOriginalOrganizationName] = useState("");
  const [CurrentOrganization, setCurrentOrganization] = useState("");
  const [TreeName, setTreeName] = useState("");
  const [TreeNo, setTreeNo] = useState("");
  const [PlantedDuring, setPlantedDuring] = useState("");
  const [FirstName, setFirstName] = useState("");
  const [isEdit, setEditFlag] = useState(false);
  const [editUserId, setID] = useState("");
  const [images, setImageData] = useState([]);
  const [image1, setImage1] = useState({});
  const [image2, setImage2] = useState({});

  const [CarbonConsumption, setCarbonConsumption] = useState("");
  const [Rainfall, setRainfall] = useState("");
  const [AtmosphericTemp, setAtmosphericTemp] = useState("");

  // const [editImages, seteditImages] = useState([]);
  const [tempTree, settempTree] = useState("");
  const [trees, setTreeData] = useState([]);
  const [editData, seteditData] = useState({});
  const [errors, seterror] = useState({
    certNo: "",
    IntegraPoCUpdated: "",
    KAMHead: "",
    CustomerName: "",
    EMail: "",
    OriginationGroupName: "",
    OriginalOrganizationName: "",
    CurrentOrganization: "",
    TreeName: "",
    PlantedDuring: "",
    CarbonConsumption:"",
    Rainfall:"",
    AtmosphericTemp:"",
    FirstName: "",
    TreeNo: ""
  });
  const [userInfo, setUserInfo] = useState("");
  useEffect(() => {
    let response = [];
    getuser(`${APIURL.API_URL}/QRCode/read`)
      .then((response) => {
        if (response.isSuccess) {
          response.data.users
            .filter((data) => data.ID)
            .map((value) => (value.CheckedForPdf = false));
          setUserInfo(response.data.users);
          setTreeData(response.data.tree);
        }
      })
      .catch((e) => {
        console.log("Error", e);
      });
    let data = JSON.parse(sessionStorage.getItem("editData"));
    if (data != null) {
      setcertNo(data.CertNo);
      setIntegraPoCUpdated(data.IntegraPoCUpdated);
      setKAMHead(data.KAMHead);
      setCustomerName(data.CustomerName);
      setEMail(data.EMail);
      setOriginationGroupName(data.OriginationGroupName);
      setOriginalOrganizationName(data.OriginalOrganizationName);
      setCurrentOrganization(data.CurrentOrganization);
      setTreeNo(data.TreeNo);
      setPlantedDuring(data.PlantedDuring);
      setCarbonConsumption(data.CarbonConsumption);
      setRainfall(data.Rainfall);
      setAtmosphericTemp(data.AtmosphericTemp);
      setFirstName(data.FirstName);
      setEditFlag(sessionStorage.getItem("isEdit"));
      setID(data.ID);
      settempTree(data.TreeName)
      // seteditImages(prevState => ({
      //   ...prevState,
      //   Image1: data.Image1,
      //   Image2: data.Image2,
      // }));
      seteditData(data)
      sessionStorage.removeItem("editData");
      sessionStorage.removeItem("isEdit");
    }
  }, []);
  let isValid = true;
  const AddUpdateUserDetails = () => {
    let user = {};
    let reg = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
    //cert no
    if (certNo != "") {
      let isExist = [];
      isExist = userInfo.filter((x) => x.CertNo == certNo);
      if (certNo.length > 50) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          certNo: "Cert No should be less than 51 characters",
        }));
      } else {
        if (!isEdit && isExist.length > 0) {
          isValid = false;
          seterror((prevState) => ({
            ...prevState,
            certNo: "Cert No already exist",
          }));
        }
        else {
          seterror((prevState) => ({
            ...prevState,
            certNo: "",
          }));
          user.CertNo = certNo;
        }
      }
    } else {
      isValid = false;
      seterror((prevState) => ({
        ...prevState,
        certNo: "Cert No should not be empty",
      }));
    }
    //IntegraPoCUpdated
    if (IntegraPoCUpdated != "") {
      if (IntegraPoCUpdated.length > 50) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          IntegraPoCUpdated:
            "IntegraPoCUpdated should be less than 51 characters",
        }));
      } else {
        seterror((prevState) => ({
          ...prevState,
          IntegraPoCUpdated: "",
        }));
        user.IntegraPoCUpdated = IntegraPoCUpdated;
      }
    } else {
      isValid = false;
      seterror((prevState) => ({
        ...prevState,
        IntegraPoCUpdated: "Integra PoC Updated should not be empty",
      }));
    }
    //KAMHead
    if (KAMHead != "") {
      if (KAMHead.length > 50) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          KAMHead: "KAM Head should be less than 51 characters",
        }));
      } else {
        seterror((prevState) => ({
          ...prevState,
          KAMHead: "",
        }));
        user.KAMHead = KAMHead;
      }
    } else {
      isValid = false;
      seterror((prevState) => ({
        ...prevState,
        KAMHead: "KAM Head should not be empty",
      }));
    }
    //TreeNo
    if (TreeNo != null && TreeNo != "") {
      if (TreeNo.length > 50) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          TreeNo: "Tree No should be less than 51 characters",
        }));
      } else {
        seterror((prevState) => ({
          ...prevState,
          TreeNo: "",
        }));
        user.TreeNo = TreeNo;
      }
    } else {
      isValid = false;
      seterror((prevState) => ({
        ...prevState,
        TreeNo: "Tree No should not be empty",
      }));
    }
    //CustomerName
    if (CustomerName != "") {
      if (CustomerName.length > 100) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          CustomerName: "Customer Name should be less than 101 characters",
        }));
      } else {
        seterror((prevState) => ({
          ...prevState,
          CustomerName: "",
        }));
        user.CustomerName = CustomerName;
      }
    } else {
      // isValid = false;
      // seterror((prevState) => ({
      //   ...prevState,
      //   CustomerName: "Customer Name should not be empty",
      // }));
      user.CustomerName = '';
    }
    //EMail
    if (EMail != "") {
      if (EMail.length > 50) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          EMail: "EMail should be less than 51 characters",
        }));
      } else if (reg.test(EMail.trim())) {
        seterror((prevState) => ({
          ...prevState,
          EMail: "",
        }));
        user.EMail = EMail;
      } else {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          EMail: "Please enter a valid email address",
        }));
      }
    } else {
      isValid = false;
      seterror((prevState) => ({
        ...prevState,
        EMail: "Email should not be empty",
      }));
    }

    //OriginationGroupName
    if (OriginationGroupName != "") {
      if (OriginationGroupName.length > 100) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          OriginationGroupName:
            "Organization Group Name should be less than 101 characters",
        }));
      } else {
        seterror((prevState) => ({
          ...prevState,
          OriginationGroupName: "",
        }));
        user.OriginationGroupName = OriginationGroupName;
      }
    } else {
      // isValid = false;
      // seterror((prevState) => ({
      //   ...prevState,
      //   OriginationGroupName: "Organization Group Name should not be empty",
      // }));
      user.OriginationGroupName = '';
    }
    //OriginalOrganizationName
    if (OriginalOrganizationName != "") {
      if (OriginalOrganizationName.length > 100) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          OriginalOrganizationName:
            "Original Organization Name should be less than 101 characters",
        }));
      } else {
        seterror((prevState) => ({
          ...prevState,
          OriginalOrganizationName: "",
        }));
        user.OriginalOrganizationName = OriginalOrganizationName;
      }
    } else {
      // isValid = false;
      // seterror((prevState) => ({
      //   ...prevState,
      //   OriginalOrganizationName:
      //     "Original Organization Name should not be empty",
      // }));
      user.OriginalOrganizationName = '';
    }
    //CurrentOrganization
    if (CurrentOrganization != "") {
      if (CurrentOrganization.length > 50) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          CurrentOrganization:
            "Current Organization should be less than 51 characters",
        }));
      } else {
        seterror((prevState) => ({
          ...prevState,
          CurrentOrganization: "",
        }));
        user.CurrentOrganization = CurrentOrganization;
      }
    } else {
      // isValid = false;
      // seterror((prevState) => ({
      //   ...prevState,
      //   CurrentOrganization: "Current Organization should not be empty",
      // }));
      user.CurrentOrganization = '';
    }
    //TreeName
    if (TreeName != "") {
      seterror((prevState) => ({
        ...prevState,
        TreeName: "",
      }));
      user.TreeName = TreeName;
    } else {
      if (isEdit) {
        if (tempTree != "") {
          seterror((prevState) => ({
            ...prevState,
            TreeName: "",
          }));
          user.TreeName = tempTree;
        }
      }
      else {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          TreeName: "Tree Name should not be empty",
        }));
      }
    }
    //PlantedDuring
    if (PlantedDuring != "") {
      if (PlantedDuring.length > 50) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          PlantedDuring: "Planted During should be less than 51 characters",
        }));
      } else {
        seterror((prevState) => ({
          ...prevState,
          PlantedDuring: "",
        }));
        user.PlantedDuring = PlantedDuring;
      }
    } else {
      isValid = false;
      seterror((prevState) => ({
        ...prevState,
        PlantedDuring: "Planted During should not be empty",
      }));
    }

    if (CarbonConsumption != "") {
      if (CarbonConsumption.length > 50) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          CarbonConsumption: "Carbon consumption in pounds should be less than 51 characters",
        }));
      } else {
        seterror((prevState) => ({
          ...prevState,
          CarbonConsumption: "",
        }));
        user.CarbonConsumption = CarbonConsumption;
      }
    } else {
      isValid = false;
      seterror((prevState) => ({
        ...prevState,
        CarbonConsumption: "Carbon consumption in pounds should not be empty",
      }));
    }


    if (Rainfall != "") {
      if (Rainfall.length > 50) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          Rainfall: "Rainfal interception in gallons should be less than 51 characters",
        }));
      } else {
        seterror((prevState) => ({
          ...prevState,
          Rainfall: "",
        }));
        user.Rainfall = Rainfall;
      }
    } else {
      isValid = false;
      seterror((prevState) => ({
        ...prevState,
        Rainfall: "Rainfal interception in gallons should not be empty",
      }));
    }


    if (AtmosphericTemp != "") {
      if (AtmosphericTemp.length > 50) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          AtmosphericTemp: "Atmospheric Temperature in fahrenheit should be less than 51 characters",
        }));
      } else {
        seterror((prevState) => ({
          ...prevState,
          AtmosphericTemp: "",
        }));
        user.AtmosphericTemp = AtmosphericTemp;
      }
    } else {
      isValid = false;
      seterror((prevState) => ({
        ...prevState,
        AtmosphericTemp: "Atmospheric Temperature in fahrenheit should not be empty",
      }));
    }



    //FirstName
    if (FirstName != "") {
      if (FirstName.length > 50) {
        isValid = false;
        seterror((prevState) => ({
          ...prevState,
          FirstName: "First Name should be less than 51 characters",
        }));
      } else {
        seterror((prevState) => ({
          ...prevState,
          FirstName: "",
        }));
        user.FirstName = FirstName;
      }
    } else {
      isValid = false;
      seterror((prevState) => ({
        ...prevState,
        FirstName: "First Name should not be empty",
      }));
    }

    if (!isEdit) {
      user.Id = 0;
    } else {
      user.Id = editUserId;
    }
    // if (images.length == 0) {
    // if(isEdit){
    //   if(editImages.Image1!=null&&user.Image1!=''){
    //     user.Image1= user.Image1
    //   }
    // }
    user.Image1 = "";
    user.Image2 = "";
    // }
    if (isValid) {
      let isuploaded = true;
      if (images.length > 0) {
        debugger;
        images.map((ImageFile, y) => {
          
          UploadImages(`${APIURL.API_URL}/QRCode/uploadImages`, ImageFile) 
            .then((response) => {
              if (response.isSuccess) {
                if (ImageFile.get("imgNo") == "1") {
                  user.Image1 = `${certNo}.jpg`;
                } else {
                  user.Image2 = `${certNo}_${ImageFile.get("imgNo")}.jpg`;
                }
                // alert("Uploaded Successfully")
              } else {
                isuploaded = false;
              }
            })
            .catch((err) => {
              isuploaded = false;
              console.log("error in Upload", err);
            });
        });
      }
      if (isuploaded) {
        if (Object.keys(user).length > 0) {
          AddUpdateUser(`${APIURL.API_URL}/QRCode/addUpdateUser`, user)
            .then((response) => {
              if (response.isSuccess) {
                try {
                  document.getElementsByClassName(
                    "uploads"
                  )[0].firstElementChild.value = "";
                  document.getElementsByClassName(
                    "uploads"
                  )[1].firstElementChild.value = "";
                } catch (err) {
                  console.log("error", err);
                }
                toast.success(
                  isEdit
                    ? "User Data Updated successfully"
                    : "User Data Added successfully"
                );
                setImage1({});
                setImage2("");
                setcertNo("");
                setIntegraPoCUpdated("");
                setKAMHead("");
                setCustomerName("");
                setEMail("");
                setOriginationGroupName("");
                setOriginalOrganizationName("");
                setCurrentOrganization("");
                setTreeName("");
                setTreeNo("");
                setPlantedDuring("");
                setCarbonConsumption("");
                setRainfall("");
                setAtmosphericTemp("");
                setFirstName("");
                setEditFlag(false);
                setID("");
                setImageData([]);
                setTreeData([]);
                if (document.getElementById("img1") != null) {
                  document.getElementById("img1").value = "";
                }
                if (document.getElementById("img2") != null) {
                  document.getElementById("img2").value = "";
                }
              } else {
                toast.success(
                  isEdit
                    ? "Failed to update User Data"
                    : "Failed to Add User Data"
                );
                setImage1({});
                setImage2("");
                setImageData([]);
                setcertNo("");
                setIntegraPoCUpdated("");
                setKAMHead("");
                setCustomerName("");
                setEMail("");
                setOriginationGroupName("");
                setOriginalOrganizationName("");
                setCurrentOrganization("");
                setTreeName("");
                setTreeNo("");
                setPlantedDuring("");
                setCarbonConsumption("");
                setRainfall("");
                setAtmosphericTemp("");
                setFirstName("");
                setEditFlag(false);
                setID("");
                setTreeData([]);
                if (document.getElementById("img1") != null) {
                  document.getElementById("img1").value = "";
                }
                if (document.getElementById("img2") != null) {
                  document.getElementById("img2").value = "";
                }
              }
            })
            .catch((err) => {
              toast.error("Error in Upload/Add");
              console.log("error in Upload", err);
            });
        } else {
          toast.error("User Data should not be Empty");
        }
      }
    } else {
      toast.error("Please fill the mandatory field to submit");
    }
  };

  const HEICtopngConverter = async(ImageFile, event, imgNo) => {
    // get image as blob url
    let blobURL = URL.createObjectURL(ImageFile);
        
    // convert "fetch" the new blob url
    let blobRes = await fetch(blobURL)

    // convert response to blob
    let blob = await blobRes.blob()

    // convert to PNG - response is blob
    let conversionResult = await heic2any({ blob : blob, toType:".jpg" })

    let container = new DataTransfer();
    let file = new File([conversionResult], "heic"+imgNo+".jpg",{type:"image/jpeg", lastModified:new Date().getTime()});
    container.items.add(file);
    
  
    if(imgNo == 1){
      document.getElementById("img1").files = container.files
      console.log("event",document.getElementById("img1").files)
    }
    else{
      document.getElementById("img2").files = container.files
      console.log("event",document.getElementById("img2").files)
    }

    
    
    // event.target.files = container.files;

    // convert to blob url
    return {"URL": URL.createObjectURL(conversionResult), "container" : container};
  }
  const uploadImage = async (event, imgNo) => {
    let url;
    let formdata = new FormData();
    if (imgNo == 1) {
      setImage1({URL:"./Spinner-1.9s-281px.svg"});
      //if HEIC file
      if(event.target.files[0] && event.target.files[0].name.includes(".HEIC")){
        url = await HEICtopngConverter(event.target.files[0], event, imgNo);
        setImage1(url);
        formdata.append("file", url.container.files[0]);
      }
      else{
          setImage1({URL: URL.createObjectURL(event.target.files[0])});
          formdata.append("file", event.target.files[0]);
      }
    } else if (imgNo == 2) {
      setImage2({URL:"./Spinner-1.9s-281px.svg"});
      if(event.target.files[0] && event.target.files[0].name.includes(".HEIC")){
        // get image as blob url
        url = await HEICtopngConverter(event.target.files[0], event, imgNo);
        setImage2(url);
        formdata.append("file", url.container.files[0]);

        // let container = new DataTransfer();
        // let file = new File([url.blob], "heic2"+".jpg",{type:"image/jpeg", lastModified:new Date().getTime()});
        // container.items.add(file);
        // event.target.files = container.files;
    
      }
      else{
        setImage2({URL: URL.createObjectURL(event.target.files[0])});
        formdata.append("file", event.target.files[0]);
      }
      
    }
      formdata.append("imgNo", imgNo);
      formdata.append("certNo", certNo);
      setImageData((prevArray) => [...prevArray, formdata]);
      console.log("images", images);
  };

  const cancelDetails = () => {
    if (!isEdit) {
      setImage1("");
      setImage2("");
      setcertNo("");
      setIntegraPoCUpdated("");
      setKAMHead("");
      setCustomerName("");
      setEMail("");
      setOriginationGroupName("");
      setOriginalOrganizationName("");
      setCurrentOrganization("");
      setTreeName("");
      setTreeNo("");
      setPlantedDuring("");
      setCarbonConsumption("");
      setRainfall("");
      setAtmosphericTemp("");
      setFirstName("");
      setEditFlag(false);
      setID("");
      setImageData([]);
      setTreeData([]);
      if (document.getElementById("img1") != null) {
        document.getElementById("img1").value = "";
      }
      if (document.getElementById("img2") != null) {
        document.getElementById("img2").value = "";
      }
    }
    else {
      let response = [];
      setcertNo(editData.CertNo);
      setIntegraPoCUpdated(editData.IntegraPoCUpdated);
      setKAMHead(editData.KAMHead);
      setCustomerName(editData.CustomerName);
      setEMail(editData.EMail);
      setOriginationGroupName(editData.OriginationGroupName);
      setOriginalOrganizationName(editData.OriginalOrganizationName);
      setCurrentOrganization(editData.CurrentOrganization);
      setTreeName(editData.TreeName);
      settempTree(editData.TreeName);
      setTreeNo(editData.TreeNo);
      setPlantedDuring(editData.PlantedDuring);
      setCarbonConsumption(editData.CarbonConsumption);
      setRainfall(editData.Rainfall);
      setAtmosphericTemp(editData.AtmosphericTemp);
      setFirstName(editData.FirstName);
      setID(editData.ID);
    }
  }
  return (
    <Card border="light" className="bg-white shadow-sm mb-4">
      <Card.Body>
        <h5 className="mb-4">
          <b>Add new user </b>
        </h5>
        <Form>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="certNo">
                <span style={{ color: "red" }}>*</span>
                <Form.Label>Certificate No.</Form.Label>
                <Form.Control
                  required
                  value={certNo}
                  onChange={(e) => {
                    if (e.currentTarget.value != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        certNo: "",
                      }));
                    }
                    setcertNo(e.currentTarget.value);
                  }}
                  type="text"
                  placeholder="Enter your Certificate No."
                />
                {errors.certNo != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.certNo}
                  </label>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="IntegraPoCUpdated">
                <span style={{ color: "red" }}>*</span>
                <Form.Label>Integra PoC Updated</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={IntegraPoCUpdated}
                  onChange={(e) => {
                    if (e.currentTarget.value != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        IntegraPoCUpdated: "",
                      }));
                    }
                    setIntegraPoCUpdated(e.currentTarget.value);
                  }}
                  placeholder="Enter the Integra PoC Updated"
                />
                {errors.IntegraPoCUpdated != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.IntegraPoCUpdated}
                  </label>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="KAMHead">
                <span style={{ color: "red" }}>*</span>
                <Form.Label>KAM Head </Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={KAMHead}
                  onChange={(e) => {
                    if (e.currentTarget.value != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        KAMHead: "",
                      }));
                    }
                    setKAMHead(e.currentTarget.value);
                  }}
                  placeholder="Enter the KAMHead "
                />
                {errors.KAMHead != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.KAMHead}
                  </label>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="CustomerName">
                <span style={{ color: "red" }}>*</span>
                <Form.Label>Customer Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={CustomerName}
                  onChange={(e) => {
                    if (e.currentTarget.value != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        CustomerName: "",
                      }));
                    }
                    setCustomerName(e.currentTarget.value);
                  }}
                  placeholder="Enter Customer Name"
                />
                {errors.CustomerName != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.CustomerName}
                  </label>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="EMail">
                <span style={{ color: "red" }}>*</span>
                <Form.Label>E-Mail</Form.Label>
                <Form.Control
                  required
                  type="email"
                  value={EMail}
                  onChange={(e) => {
                    if (e.currentTarget.value != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        EMail: "",
                      }));
                    }
                    setEMail(e.currentTarget.value);
                  }}
                  placeholder="name@company.com"
                />
                {errors.EMail != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.EMail}
                  </label>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="grpname">
                <span style={{ color: "red" }}>*</span>
                <Form.Label>Origination Group Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={OriginationGroupName}
                  onChange={(e) => {
                    if (e.currentTarget.value !== "") {
                      seterror((prevState) => ({
                        ...prevState,
                        OriginationGroupName: "",
                      }));
                    }
                    setOriginationGroupName(e.currentTarget.value);
                  }}
                  placeholder="Enter Org Group Name"
                />
                {errors.OriginationGroupName != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.OriginationGroupName}
                  </label>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="OriginalOrganizationName">
                <span style={{ color: "red" }}>*</span>
                <Form.Label>Original Organization Name </Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={OriginalOrganizationName}
                  onChange={(e) => {
                    if (e.currentTarget.value != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        OriginalOrganizationName: "",
                      }));
                    }
                    setOriginalOrganizationName(e.currentTarget.value);
                  }}
                  placeholder="Enter Original Organizaion Name"
                />
                {errors.OriginalOrganizationName != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.OriginalOrganizationName}
                  </label>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="CurrentOrganization">
                <span style={{ color: "red" }}>*</span>
                <Form.Label>Current Organization </Form.Label>
                <Form.Control
                  type="text"
                  value={CurrentOrganization}
                  onChange={(e) => {
                    if (e.currentTarget.value != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        CurrentOrganization: "",
                      }));
                    }
                    setCurrentOrganization(e.currentTarget.value)
                  }
                  }
                  placeholder="Enter Current Organizaion Name"
                />
                {errors.CurrentOrganization != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.CurrentOrganization}
                  </label>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col md={6} className="mb-3">
              <Form.Group id="TreeName">
                <span style={{ color: "red" }}>*</span>
                <Form.Label>Tree Name</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  className="Treesample"
                  id="DropDown"
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    if (e.currentTarget.value != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        TreeName: "",
                      }));
                    }
                    // console.log(e.currentTarget.value)
                    setTreeName(e.currentTarget.value);
                    settempTree(e.currentTarget.value)
                  }}
                  name="treelist"
                >
                  <option >Select</option>
                  {trees != "" &&
                    trees.map((data, index) => {
                      if (data.ID && data.ID != null) {
                        return (
                          <option key={index} value={data.ID} selected={isEdit && data.ID == tempTree && tempTree != '' ? true : false}>
                            {data.TreeName}
                          </option>
                        );
                      }
                    })}
                </Form.Select>
                {errors.TreeName != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.TreeName}
                  </label>
                ) : null}
              </Form.Group>
            </Col>
            {/* <Col md={6} className="mb-3">
              <Form.Group id="FirstName">
                <span style={{ color: "red" }}>*</span>
                <Form.Label>FirstName</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={FirstName}
                  onChange={(e) => {
                    if(e.currentTarget.vale != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        FirstName: "",
                      }));
                    } setFirstName(e.currentTarget.value) }}
                  placeholder="Enter FirstName"
                />
                {errors.FirstName != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.FirstName}
                  </label>
                ) : null}
              </Form.Group>
            </Col> */}
            <Col md={6} className="mb-3">
              <Form.Group id="TreeNo">
                <span style={{ color: "red" }}>*</span>
                <Form.Label>Tree No.</Form.Label>
                <Form.Control
                  className="TreeDesc"
                  required
                  type="text"
                  value={TreeNo}
                  onChange={(e) => {
                    if (e.currentTarget.value != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        TreeNo: "",
                      }));
                    }
                    setTreeNo(e.currentTarget.value)
                  }}
                  placeholder="Enter Tree No"
                />
                {errors.TreeNo != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.TreeNo}
                  </label>
                ) : null}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6} className="mb-3">
              <Form.Group id="PlantedDuring">
                <span style={{ color: "red" }}>*</span>
                <Form.Label>Planted During</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={PlantedDuring}
                  onChange={(e) => {
                    if (e.currentTarget.vale != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        PlantedDuring: "",
                      }));
                    }
                    setPlantedDuring(e.currentTarget.value)
                  }}
                  placeholder="Enter Planted During"
                />
                {errors.PlantedDuring != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.PlantedDuring}
                  </label>
                ) : null}
              </Form.Group>
            </Col>
            <Col md={6} className="mb-3">
              <Form.Group id="FirstName">
                <span style={{ color: "red" }}>*</span>
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={FirstName}
                  onChange={(e) => {
                    if (e.currentTarget.vale != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        FirstName: "",
                      }));
                    } setFirstName(e.currentTarget.value)
                  }}
                  placeholder="Enter First Name"
                />
                {errors.FirstName != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.FirstName}
                  </label>
                ) : null}
              </Form.Group>
            </Col>
          </Row>


 <Row>
            <Col md={4} className="mb-3">
              <Form.Group id="CarbonConsumption">
                <Form.Label>
                <span style={{ color: "red" }}>*</span>
                Carbon consumption(pounds)
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={CarbonConsumption}
                  onChange={(e) => {
                    if (e.currentTarget.vale != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        CarbonConsumption: "",
                      }));
                    }
                    setCarbonConsumption(e.currentTarget.value)
                  }}
                  placeholder="Carbon consumption(pounds)"
                />
                {errors.CarbonConsumption != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.CarbonConsumption}
                  </label>
                ) : null}
              </Form.Group>
            </Col>

            <Col md={4} className="mb-3">
              <Form.Group id="Rainfall">
                <Form.Label>
                <span style={{ color: "red" }}>*</span>
                Rainfal interception (gallons)
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={Rainfall}
                  onChange={(e) => {
                    if (e.currentTarget.vale != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        Rainfall: "",
                      }));
                    }
                    setRainfall(e.currentTarget.value)
                  }}
                  placeholder="Rainfal interception (gallons)"
                />
                {errors.Rainfall != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.Rainfall}
                  </label>
                ) : null}
              </Form.Group>
            </Col>

            <Col md={4} className="mb-3">
              <Form.Group id="AtmosphericTemp">
                <Form.Label>
                <span style={{ color: "red" }}>*</span>
                Atmospheric Temperature (fahrenheit)
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  value={AtmosphericTemp}
                  onChange={(e) => {
                    if (e.currentTarget.vale != "") {
                      seterror((prevState) => ({
                        ...prevState,
                        AtmosphericTemp: "",
                      }));
                    }
                    setAtmosphericTemp(e.currentTarget.value)
                  }}
                  placeholder="Atmospheric Temperature (fahrenheit)"
                />
                {errors.AtmosphericTemp != "" ? (
                  <label style={{ margin: "0px", color: "red" }}>
                    <FontAwesomeIcon icon={faInfo} className="me-2" />{" "}
                    {errors.AtmosphericTemp}
                  </label>
                ) : null}
              </Form.Group>
            </Col>

          </Row>


          <h5 style={{ marginTop: "2em" }}>Upload Images</h5>
          <Row>
            <Col md={6}>
              <Form.Group id="Image_1">
                <Form.Label>Image-1 (Certificate-1 / Long shot Image)</Form.Label>
                <Card border="light" className="bg-white shadow-sm mb-4">
                  <Card.Body>
                    <div className="img1">
                      {image1 != "" ? (
                        <img className="output" src={image1.URL}></img>
                      ) : isEdit ? (
                        <img
                          className="output"
                          // src={`https://crossorigin.azurewebsites.net/proxy/?https://livewkldigitalbooks.blob.core.windows.net/titlestesting/QRCode/${isEdit ? editData.CertNo : certNo}_1.jpg`}
                          src={`https://qrgreencode.blob.core.windows.net/qrcode/QRCode/${isEdit ? editData.CertNo : certNo}.jpg`}
                          onError={(e) => {
                            e.target.src = `https://qrgreencode.blob.core.windows.net/qrcode/QRCode/${isEdit ? editData.CertNo : certNo}.JPG`
                          }}
                        ></img>
                      ) : (
                            ""
                          )}
                    </div>
                    <div className="uploads">
                      <input
                        id='img1'
                        type="file"
                        name="img1"
                        onChange={(evt) => {
                          uploadImage(evt, 1);
                        }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group id="Image_2">
                <Form.Label>Image-2 (Certificate-2 / Close shot Image)</Form.Label>
                <Card border="light" className="bg-white shadow-sm mb-4">
                  <Card.Body>
                    <div className="img2">
                      {image2 != "" ? (
                        <img className="output" src={image2.URL}></img>
                      ) : isEdit ? (
                        <img
                          className="output"
                          // src={`https://crossorigin.azurewebsites.net/proxy/?https://livewkldigitalbooks.blob.core.windows.net/titlestesting/QRCode/${isEdit ? editData.CertNo : certNo}_2.jpg`}
                          src={`https://qrgreencode.blob.core.windows.net/qrcode/QRCode/${isEdit ? editData.CertNo : certNo}_2.jpg`}
                          onError={(e) => {
                            e.target.src = `https://qrgreencode.blob.core.windows.net/qrcode/QRCode/${isEdit ? editData.CertNo : certNo}_2.JPG`
                          }}
                        ></img>
                      ) : (
                            ""
                          )}
                    </div>
                    <div className="uploads">
                      <input
                        id='img2'
                        type="file"
                        name="img2"
                        onChange={(evt) => {
                          uploadImage(evt, 2);
                        }}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Form.Group>
            </Col>
          </Row>
          <div style={{ display: 'flex' }}>
            <div className="mt-3" >
              <Button variant="primary" onClick={AddUpdateUserDetails}>
                Submit
            </Button>
            </div>
            <div className="mt-3" style={{ marginLeft: '2rem' }}>

              <Button variant="primary" onClick={cancelDetails}>
                Cancel
            </Button>
            </div>
          </div>
        </Form>
        <ToastContainer
          autoClose={3000}
          hideProgressBar={true}
          limit={0}
          draggable={false}
          position={toast.POSITION.TOP_RIGHT}
        ></ToastContainer>
      </Card.Body>
    </Card>
  );
};
